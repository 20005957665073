import { Github, Twitter, Codepen, Instagram, Moon, Linkedin, Sun, Code, Monitor, Smartphone } from "styled-icons/feather";
import { 
    Vite, 
    Nestjs, 
    Angular, 
    Ios, 
    Swift, 
    ReactLogo, 
    Gatsby, 
    Javascript, 
    Styledcomponents, 
    Typescript, 
    Mongodb, 
    Prisma, 
    Jsonwebtokens, 
    Nextdotjs, 
    Tailwindcss,
    Apple,
    Github as Github2,
    Html5,
    Css3,
    Nodedotjs,
    Mysql,
    Postgresql,
    Amazonaws,
    Azuredevops,
    Jest,
    Jasmine,
    Jira,
    Contentful,
    Git,
    Sass,
    Materialui,
} from "styled-icons/simple-icons";
import {Monitor as FilledMonitor} from "styled-icons/material-twotone"
import {Close, AccessibilityNew} from "styled-icons/material-rounded"
import {MenuAlt3, ArrowSmRight} from "styled-icons/heroicons-solid"
import {TestTube} from "styled-icons/remix-line"
import {Kanban} from "styled-icons/bootstrap/"


const Icons = {
    Github,
    Github2,
    Twitter,
    Codepen,
    Instagram,
    Moon,
    Linkedin,
    Sun,
    Code, 
    Monitor, 
    Smartphone,
    Ios, 
    Vite,
    Nestjs, 
    Angular, 
    Swift, 
    ReactLogo, 
    Gatsby, 
    Javascript, 
    Styledcomponents, 
    Typescript, 
    Mongodb, 
    Prisma, 
    Jsonwebtokens, 
    Nextdotjs, 
    Tailwindcss,
    Apple,
    FilledMonitor,
    Close,
    MenuAlt3,
    ArrowSmRight,
    Html5,
    Css3,
    Nodedotjs,
    Mysql,
    Postgresql,
    Amazonaws,
    Azuredevops,
    TestTube,
    Jest,
    Jasmine,
    Jira,
    Kanban,
    AccessibilityNew,
    Contentful,
    Git,
    Sass,
    Materialui
}

export default Icons